<template>
    <div style="width: 100%;">
        <p class="text-center mt-4">Logging out...</p>
    </div>
</template>
<script>
import { mapMutations } from 'vuex';
export default {
    data(){
        return {
        }
    },
    methods: {
        ...mapMutations(['setUser', 'setExisting']),
        logout(){
            this.setUser({});
            window.localStorage.clear();
            if(location.href.indexOf('closed') === -1){
                this.setExisting(true);
            }
            this.proceed();
        },
        proceed(){
            if(location.href.indexOf('canceled') !== -1){
                this.setExisting(false);
                this.$router.push('/');
            }else{
                if(location.href.indexOf('closed') !== -1){
                    this.$router.push('/login?closed');
                }else{
                    this.$router.push('/login?logged-out');
                }
            }
        }
    },
    mounted(){
        this.logout();
    }
}
</script>